import React, { useEffect, useRef } from 'react';
import * as tf from '@tensorflow/tfjs';

const labels = [
    "Человек",
    "Велосипед",
    "Автомобиль",
    "Мотоцикл",
    "Самолет",
    "Автобус",
    "Поезд",
    "Грузовик",
    "Лодка",
    "Светофор",
    "Пожарный гидрант",
    "Знак остановки",
    "Парковочный счетчик",
    "Скамейка",
    "Птица",
    "Кошка",
    "Собака",
    "Лошадь",
    "Овца",
    "Корова",
    "Слон",
    "Медведь",
    "Зебра",
    "Жираф",
    "Рюкзак",
    "Зонтик",
    "Саквояж",
    "Галстук",
    "Чемодан",
    "Фрисби",
    "Лыжи",
    "Чноуборд",
    "Спортивный мяч",
    "Воздушный змей",
    "Бейсбольная бита",
    "Бейсбольная перчатка",
    "Скейтборд",
    "Доска для серфинга",
    "Теннисная ракетка",
    "Бутылка",
    "Винный бокал",
    "Чашка",
    "Вилка",
    "Нож",
    "Ложка",
    "Миска",
    "Банан",
    "Яблоко",
    "Сэндвич",
    "Апельсин",
    "Брокколи",
    "Морковь",
    "Хот-дог",
    "Пицца",
    "Пончик",
    "Торт",
    "Стул",
    "Диван",
    "Комнатное растение",
    "Кровать",
    "Обеденный стол",
    "Туалет",
    "Телевизор",
    "Ноутбук",
    "Мышь",
    "Пульт",
    "Клавиатура",
    "Мобильный телефон",
    "Микроволновая печь",
    "Духовка",
    "Тостер",
    "Раковина",
    "Холодильник",
    "Книга",
    "Часы",
    "Ваза",
    "Ножницы",
    "Плюшевый мишка",
    "Фен",
    "Зубная щетка"
]

function xywh2xyxy(x) {
    //Convert boxes from [x, y, w, h] to [x1, y1, x2, y2] where xy1=top-left, xy2=bottom-right
    var y = [];
    y[0] = x[0] - x[2] / 2  //top left x
    y[1] = x[1] - x[3] / 2  //top left y
    y[2] = x[0] + x[2] / 2  //bottom right x
    y[3] = x[1] + x[3] / 2  //bottom right y
    return y;
}

function non_max_suppression(res, conf_thresh = 0.50, iou_thresh = 0.2, max_det = 300) {

    // Initialize an empty list to store the selected boxes
    const selected_detections = [];

    for (let i = 0; i < res.length; i++) {

        // Check if the box has sufficient score to be selected
        if (res[i][4] < conf_thresh) {
            continue;
        }

        var box = res[i].slice(0, 4);
        const cls_detections = res[i].slice(5);
        var klass = cls_detections.reduce((imax, x, i, arr) => x > arr[imax] ? i : imax, 0);
        const score = res[i][klass + 5];

        let object = xywh2xyxy(box);
        let addBox = true;


        // Check for overlap with previously selected boxes
        for (let j = 0; j < selected_detections.length; j++) {
            let selectedBox = xywh2xyxy(selected_detections[j]);

            // Calculate the intersection and union of the two boxes
            let intersectionXmin = Math.max(object[0], selectedBox[0]);
            let intersectionYmin = Math.max(object[1], selectedBox[1]);
            let intersectionXmax = Math.min(object[2], selectedBox[2]);
            let intersectionYmax = Math.min(object[3], selectedBox[3]);
            let intersectionWidth = Math.max(0, intersectionXmax - intersectionXmin);
            let intersectionHeight = Math.max(0, intersectionYmax - intersectionYmin);
            let intersectionArea = intersectionWidth * intersectionHeight;
            let boxArea = (object[2] - object[0]) * (object[3] - object[1]);
            let selectedBoxArea = (selectedBox[2] - selectedBox[0]) * (selectedBox[3] - selectedBox[1]);
            let unionArea = boxArea + selectedBoxArea - intersectionArea;

            // Calculate the IoU and check if the boxes overlap
            let iou = intersectionArea / unionArea;
            if (iou >= iou_thresh) {
                addBox = false;
                break;
            }
        }

        // Add the box to the selected boxes list if it passed the overlap check
        if (addBox) {
            const row = box.concat(score, klass);
            selected_detections.push(row);
        }
    }

    return selected_detections
}

function shortenedCol(arrayofarray, indexlist) {
    return arrayofarray.map(function (array) {
        return indexlist.map(function (idx) {
            return array[idx];
        });
    });
}

const ObjectDetector = () => {
    const videoRef = useRef(null);
    const canvasRef = useRef(null);

    useEffect(() => {
        const loadModelAndDetectObjects = async () => {
            const model = await tf.loadGraphModel('/yolov7_web_model/model.json');

            const detectFrame = async () => {
                const model_dim = [640, 640];
                const input = tf.tidy(() => {
                    const img = tf.image
                        .resizeBilinear(tf.browser.fromPixels(videoRef.current), model_dim)
                        .div(255.0)
                        .transpose([2, 0, 1])
                        .expandDims(0);
                    return img;
                });

                const res = await model.executeAsync(input);
                const detections = non_max_suppression(res.arraySync()[0]);
                const boxes = shortenedCol(detections, [0, 1, 2, 3]);
                const scores = shortenedCol(detections, [4]);
                const class_detect = shortenedCol(detections, [5]);

                // Очистить канвас перед новой отрисовкой
                const ctx = canvasRef.current.getContext('2d');
                ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

                // Отображение рамок объектов и меток
                for (let i = 0; i < boxes.length; i++) {
                    if (scores[i][0] > 0.8) {
                        const xOffset = -50;
                        const yOffset = -150;

                        const [x, y, width, height] = boxes[i];
                        ctx.strokeStyle = 'violet';
                        ctx.lineWidth = 2;
                        ctx.strokeRect(x + xOffset, y + yOffset, width, height);

                        ctx.fillStyle = 'violet';
                        ctx.font = '18px Arial';
                        ctx.fillText(`${labels[class_detect[i][0]]} (${Math.round(scores[i][0] * 100)}%)`, x + xOffset + 5, y + yOffset + 20);
                    }
                }

                tf.dispose(res);

                requestAnimationFrame(() => detectFrame(model));
            };

            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                videoRef.current.srcObject = stream;
                videoRef.current.onloadedmetadata = () => {
                    videoRef.current.play();
                    canvasRef.current.width = videoRef.current.videoWidth;
                    canvasRef.current.height = videoRef.current.videoHeight;
                    detectFrame();
                };
            }

        };

        loadModelAndDetectObjects();
    }, []);

    return (
        <div>
            <video
                ref={videoRef}
                width="640"
                height="480"
                autoPlay
                muted
                style={{ position: "absolute" }}
            />
            <canvas
                ref={canvasRef}
                style={{ position: "absolute" }}
            />
        </div>
    );
};

export default ObjectDetector;
